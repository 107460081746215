import * as React from "react";
import ResponsiveMedia from "utils/responsive_media";

import Seo from "components/seo";
import LayoutBanner from "../../templates/layoutBanner";
import LegalityWorld from "../../organisms/legalityWorld";
import { WORLD_LEGALITY_META, WORLD_LEGALITY_META_EN } from "../../constants/data/meta_data";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const LegalityWorldPage = () => {
    const { isMobile } = ResponsiveMedia();
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return WORLD_LEGALITY_META_EN;

        return WORLD_LEGALITY_META;
    }, [i18n.language]);

    return (
        <LayoutBanner bgColor={isMobile === "true" ? "#fff" : "#f8f8f8"}>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <LegalityWorld />
        </LayoutBanner>
    );
};

export default LegalityWorldPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
